/*
 * Name: Create Party
 * Desc:
 *
*/

@import "@styles/variables.scss";

.CreatePartyModal {
  background: $grey-background;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media screen and (min-width: $netbook) {
    min-height: 0;
  }
  input {
    margin: 0 0 8px;
  }
  label {
    margin-top: 10px;
    margin-bottom: 15px;
    display: block;
  }
  .buttons {
    button {
      width: 100%;
      margin-bottom: $base-3;
      margin-top: $base-2;
    }
  }

  .twoButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $base-2;
  }
  .buttons {
  }
  .select {
    height: 55px;
    display: flex;
    input {
      padding-left: $base-3;
      border: none;
    }
  }
}

.full {
  flex: 1;
}

.fullModal {
  position: absolute;
  & > section {
    max-width: 100%;
  }
}
