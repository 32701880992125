/*
 * Name: Sign Up Header
 * Desc:
 *
*/

@import '@styles/variables.scss';

.SiteLogo {
    display:flex;
    align-items:center;
    &:hover .bow {
        animation-duration: 0.8s;
        animation-iteration-count: 1;
        animation-name: wobble;
        animation-timing-function: ease-in-out;
    }
}

@keyframes wobble {

    25%,
    75% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(2.5px);
    }
  }


// .SignUpHeader {
//     background: $dark-background;
//     padding:$base-4 0 $base-5;
//     overflow: hidden;
//     @media screen and (min-width:$tablet) {
//         padding:$base-2 0 $base-5;
//     }
//     .inner {
//         margin-left: (100% - $site-width-m)/2;
//         margin-right:(100% - $site-width-m)/2;
//         display: grid;
//         grid-template-columns: 1fr;
//         column-gap: $base-2;
//         row-gap: $base-4;
//         align-items: center;
//         @media screen and (min-width:$tablet) {
//             width:100%;
//             grid-template-columns: 1fr 1fr;
//             margin-right:0;
//         }
//         @media screen and (min-width:$netbook) {
//             grid-template-columns: 1fr 60%;
//             margin-left: (100% - $site-width-d)/2;
//         }
//         @media screen and (min-width:$desktop) {
//             max-width: $site-max-width;
//             width: $site-width-d;
//             height: 620px;
//             margin: auto;
//             margin-left: auto;
//             display: block;
//             position: relative;
//             .text {
//                 position: relative;
//                 top:50%;
//                 transform: translateY(-50%);
//             }
//             .image {
//                 position: absolute;
//                 height:620px ;
//                 right:-30%;
//                 top:50%;
//                 transform: translateY(-50%);
//                 z-index: 1;
//             }
//         }

//         .text {
//             color: $light-text;
//             a {
//                 color: $link-text;
//             }
//             p {
//                 max-width:400px;
//             }
//         }
//         .image {
//             img {
//                 width:118%;
//                 margin-right: -18%;
//                 @media screen and (min-width:$desktop) {
//                     height: 620px;
//                     width: auto;
//                     margin-right: 0px;
//                 }
//             }
//         }
//     }
// }