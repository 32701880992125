/*
 * Name: Create Party
 * Desc:
 *
*/

@import "@styles/variables.scss";

.SelectPartyServices {
  background: $grey-background;
  padding-bottom: $base-3;
  .serviceCategoriesColumn {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  .serviceCategories {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
    .serviceCategory {
        margin-bottom: 30px;
        display: grid;
        row-gap: $base-1;
        h3 {
            margin-bottom: $base-1;
        }
    }
    .service {
       display: flex;
       align-items: center;
       width: 100%;
       border-radius: 5px;
       background: $white-rhino;
       border: 1px solid transparent; 
       cursor: pointer;
       user-select: none;
       transition: all 200ms ease-in-out;
       label {
        cursor: pointer;
        user-select: none;
       }
       &.active {
        border: 1px solid $hopp-dark-blue; 
         background: $soft-grey;
       }
       img {
           height: 30px;
           padding: 10px;
           padding-top: 5px;
       }
       &:hover {
         border: 1px solid $hopp-dark-blue; 
       }
    }
  }
  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $base-2;
  }
}

