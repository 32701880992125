/*
 * Name: Create Party
 * Desc:
 *
*/

@import '@styles/variables.scss';


.NewPartyModal {
    background: $grey-background;

    form {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        @media screen and (min-width:$netbook) {
            min-height: 0;
        }

        h2 {
            text-align: center;
            height: $header-height-m;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                display: block;
                width:100%;
            }
        }
        h3 {
            justify-content: center;
        }
        p {
            max-width: 400px;
            margin: auto;
            font-weight: 500;
            font-size: 16px;
        }
    }
    .full {
        flex: 1;
    }

    .stepOne {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        @media screen and (min-width:$netbook) {
            min-height: 0;
        }
        input {
            margin: 0 0 8px;
        }
        label {
            margin-top: 10px;
            margin-bottom: 15px;
            display: block;
        }
        button {
            width:100%;
            margin-bottom: $base-3;
        }
    }


}

div.largeModal {
    max-width: 850px;
    & > section {
        max-width: 850px;
    }
}
