/*
 * Name: Create Party
 * Desc:
 *
*/

@import "@styles/variables.scss";

.inner {
  background: $grey-background;
}
div {
  @media screen and (min-width: $netbook) {
    &.largeModal {
      max-width: 850px;
      & > section {
        max-width: 850px;
      }
    }
  }
}
