/*
 * Name: About
 * Desc:
 *
*/

@import '@styles/variables.scss';

.ThankYou {
    background: $grey-background;
  display: flex;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media screen and (min-width: $netbook) {
    padding: $base-4;
    box-sizing: border-box;
  }
  
  .animation {
    margin-top: -50px;
  }
  .text {
    max-width: 500px;
    margin: auto;
    transition: all 300ms ease-in-out;
    opacity: 0;
    transform: translateY(-90px);
    &.completed {
      opacity: 1;
    }
  }
  h2 {
    text-transform: none;
  }
  p {
    margin: 0;
    + p {
      margin-top: $base-2;
    }
  }
  a {
    margin-top: $base-1;
    margin-left: auto;
    margin-right: auto;
  }

}