/*
 * Name: Button
 * Desc:
 *
*/

@import '@styles/variables.scss';


.Button {
    //reset default
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 25px;
    background: none;
    border: none;
    color: $light-text;
    border-radius: 0px;
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1.39px;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;

    svg {
        margin-right: $base-1;
        width: 14px;
        height: auto;
    }
    .mainImage {
        display: block;
    }
    .hoverImage {
        display: none;
    }

    &:hover {
        .mainImage {
            display: none;
        }
        .hoverImage {
            display: block;
        }
    }

    &.hidden {
        display: none;
    }

    &.small {
        border-radius: 1px;
        padding: 7px;
        letter-spacing: 1px;
        font-size: 12px;
        svg {
            margin-right: $base-s;
            height: 14px;
        }
        &.dark {
            color:$dark-text;
            font-weight: 600;
            background: $white;
        }
        transition: all 200ms ease-in-out;
        opacity: 0.85;
        &:hover {
            opacity: 0.99;

        }
    }
    &.large {
        width: 100%;
    }



    @media screen and (max-width:$tablet - 1px) {
        &.mobileImageOnly {
            text-indent: -100vw;
            overflow: hidden;
            font-size: 0px;
            padding-right: 0;
            svg {
                margin-right: 0px;
            }
        }
    }

    &.link {
        width:fit-content;
    }

    &.image {
        padding: 0;
        background: none;
        border:none;
        svg {
            margin: 0;
            width: inherit;
        }

        &.grey {
            color: $soft-grey;
            text-transform: lowercase;
            svg {
                margin-right: $base-1;
            }
        }
    }
    &.imageRight {
        svg {
            order:3;
            margin-right: 0;
            margin-left: $base-1;
        }
    }
    &.roundedTextImage {
        background: $light-grey;
        border-radius: 5px;
        color: $dark-text;
        text-transform: none;
        font-weight: 500;
        font-size: 14px;
        padding: 0;
        padding-left: $base-2;
        transition: all 200ms ease-in-out;
        text-align: left;
        height: 45px;
        svg {
            background: $darkgrey-background;
            order:3;
            margin-right: 0;
            margin-left: $base-1*1.5;
            padding: $base-1*1.5;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
           
        }
        &:hover {
            opacity: 0.9;
        }
      
    }

    &.text {
        padding: 0;
        background: none;
        border:none;
        color: $grey-text;
        font-size: 10px;
        font-weight: 600;
        &.large {
            font-size: 15px;
            letter-spacing: 0.8px;
            &.orange {
                color:rgba($hopp-orange, 1);
                opacity: 0.8;
                &:hover {
                    opacity: 1;
                }
            }
        }
        &.pink {
            text-transform: none;
            color: $hopp-pink;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
        }
        &.white {
            color: $light-text;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 1.2px;
        }
        &.dark {
            color: $dark-text;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 1.2px;
            border-bottom: 1px solid transparent;
            transform: all 200ms ease;
            &:hover {
                border-bottom: 1px solid $dark-text;
            }
        }
        &.menu {
            font-size:17px;
            @media screen and (min-width:$netbook) {
                font-size: 13px;
            }
        }
    }


    //colors
    &.lightBlue {
        &.solid {
            background-color: $hopp-sky-blue;
            &:hover {
                background: lighten($hopp-sky-blue, 8%) radial-gradient(circle, transparent 1%, lighten($hopp-sky-blue, 8%) 1%) center/15000%;
            }
            &:active {
                background-color: lighten($hopp-sky-blue, 20%);
            }
            &, a {
                color: $light-text;
            }
        }
        &.outline {
            border-color: $hopp-sky-blue;
            &, a {
                color: $hopp-sky-blue;
            }
        }
    }
    &.orange {
        &.solid {
            background-color: $hopp-orange;
            &:hover {
                background: lighten($hopp-orange, 8%) radial-gradient(circle, transparent 1%, lighten($hopp-orange, 8%) 1%) center/15000%;
            }
            &:active {
                background-color: lighten($hopp-orange, 20%);
            }
            &, a {
                color: $light-text;
            }
        }
        &.outline {
            border-color: $hopp-orange;
            &, a {
                color: $hopp-orange;
            }
        }
    }

    &.pink {
        &.solid {
            background-color: $hopp-pink;
            &:hover {
                background: lighten($hopp-pink, 4%) radial-gradient(circle, transparent 1%, lighten($hopp-pink, 4%) 1%) center/15000%;
            }
            &:active {
                background-color: lighten($hopp-pink, 10%);
            }
            &, a {
                color: $light-text;
            }
        }
        &.outline {
            border-color: $hopp-pink;
            &, a {
                color: $hopp-pink;
            }
        }
    }
    &.white {
        &.solid {
            background-color: $white;
            &:hover {
                background: darken($white, 8%) radial-gradient(circle, transparent 1%, darken($white, 8%) 1%) center/15000%;
            }
            &:active {
                background-color: darken($white, 20%);
            }
            &, a {
                color: $dark-text;
                font-weight: 600;
            }
        }
        &.outline {
            border-color: $white;
            &, a {
                color: $white;
                font-weight: 600;
            }
        }
    }
    &.grey {
        &.solid {
            background-color: $light-line;
            &:hover {
                background:lighten($light-line, 4%) radial-gradient(circle, transparent 1%, lighten($light-line, 4%) 1%) center/15000%;
            }
            &:active {
                background-color: lighten($light-line, 8%);
            }
            &, a {
                color: $grey-text;
                font-weight: 600;
            }
        }
        &.outline {
            border-color: lighten($faded-grey, 20%);
            color: $faded-grey;

            &, a {
                color: $soft-grey-text;
                font-weight: 400;
                &:hover {
                    color: darken($soft-grey-text, 40%);
                    border-color: $faded-grey;
                }
            }
            &.small {
                transform: none;
                box-shadow: none;

            }

        }
    }
    &.dark {
        &.solid {
            background-color: $dark-background;
            &:hover {
                background: lighten($dark-background, 8%) radial-gradient(circle, transparent 1%, lighten($dark-background, 8%) 1%) center/15000%;
            }
            &:active {
                background-color: lighten($dark-background, 20%);
            }
            &, a {
                color: $light-text;
                font-weight: 600;
            }
        }
        &.outline {
            border-radius: 5px;
            padding: 15px 45px;
            border-color: $dark-background;
            &, a {
                color: $dark-background;
                font-weight: 600;
            }
        }
    }

    &.solid {
        border-radius: 3px;
        box-shadow: $elevation-2;
        // box-shadow: 0 3px 18px 2px rgba(19, 23, 48, 0.05);
        //ripple effect
        background-position: center;
        transition: background 0.6s;
        &:active {
            background-size: 100%;
            transition: background 0s;
        }
        &:disabled {
            background-color: $soft-grey;
            &:hover {
                background-color: $soft-grey;
                cursor: wait;
            }
        }
    }

    &.outline {
        border: 1px solid;
        transition: all 0.3s;
        &:disabled {
            color: $soft-grey;
            border-color: $soft-grey;
            &:hover {
                color: $soft-grey;
                border-color: $soft-grey;
            }
        }
    }

    &.rounded {
        border-radius: 50px;
    }

    &.linkStyle {
        @media screen and (max-width:$netbook) {
            border: 1px solid $light-line;
        }
        background: none;
        color: $dark-text;
        box-shadow: none;
        font-size: 11px;
        font-weight: 600;
        padding: 0;
        padding: $base-1;
        border: 1px solid $light-line;
        border-radius: 2px;
        transition: all 200ms ease-in-out;
        &:hover {
            border: 1px solid $light-line;
        }
        svg {
            margin-top: -2px;
        }

    }


}