/*
 * Name: SimpleIntro
 * page: global
 *
*/

@import '@styles/variables.scss';

.SimpleIntro {
    text-align: center;
    padding: $section-m 0;
    h1 {
        margin: $base-1+$base-s 0 $base-1;
        font-size: 25px;
        text-transform: none;
        @media screen and (min-width:$netbook) {
            font-size: 30px;
        }
    }
    p {
        line-height: 1.6;
        margin: 0;
        @media screen and (min-width:$netbook) {
            font-size: 18px;
            font-weight: 500;
        }
        strong {
            font-size: 17px;
        }
    }
    img {
        max-height: 40px;
        width: 100%;
    }
}
