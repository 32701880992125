/*
 * Name: CategoryGrid
 * page: global
 *
*/

@import "@styles/variables.scss";

.CategoryGrid {
  overflow: auto;
  @media screen and (min-width: $netbook) {
    padding-bottom: $base-4;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: $base-1;
    column-gap: $base-1;
    @media screen and (min-width: $tablet) {
      grid-template-columns: repeat(4, 1fr);
      max-width: 600px;
      margin: auto;
    }
    @media screen and (min-width: $netbook) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1100px;
      margin: auto;
    }
    .box {
      position: relative;
      @media screen and (min-width: $netbook) {
        width: 128px;
        height: 144px;
        margin: 7px;
      }

      .newIcon {
        position: absolute;
        top: 6px;
        left: 6px;
        z-index: 1;
        color: $virtual;
        border: 1px solid $virtual;
        font-size: 10px;
        text-transform: uppercase;
        line-height: 1;
        padding: 3px;
        vertical-align: middle;
        font-weight: 600;
      }

      .boxInner {
        background: $white;
        text-transform: none;
        font-weight: 500;
        display: block;
        padding: $base-2 $base-s;
        border: 1px solid transparent;
        cursor: pointer;
        text-align: center;
        height: 100%;
        box-sizing: border-box;
        position: relative;
        transition: all 200ms ease-in-out;

        &.virtual {
          background: $virtual-pale;
          span {
            font-weight: 600;
          }
        }

        span {
          display: block;
          margin-top: $base-1;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 1.2;
          color: $dark-text;
          @media screen and (min-width: $netbook) {
            font-size: 14px;
          }
        }
        .images {
          position: relative;
          max-width: 50px;
          margin: auto;
          img {
            transition: opacity 200ms ease-in-out;
            img:nth-child(1) {
              opacity: 1;
            }
            &:nth-child(2) {
              position: absolute;
              left: 0;
              opacity: 0;
            }
          }
        }
        &:hover {
          border-color: $hopp-sky-blue;
          img:nth-child(1) {
            opacity: 0;
          }
          img:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }
  }

  &.inModal {
    // max-height: 500px;
    padding-bottom: $base-4;
    .grid {
     
      @media screen and (min-width: $netbook) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
}
