/*
 * Name: LogIn
 * Desc:
 *
*/

@import '@styles/variables.scss';

.LogOut {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    font-size:17px;
    text-transform: none;
    img {
        margin-right: 10px;
    }
    @media screen and (min-width:$netbook) {
        text-transform: uppercase;
    }
}