/*
 * Name: Sign In
 * Desc:
 *
*/

@import "@styles/variables.scss";

.SignUp {
  padding-bottom: $base-4;
  background: $grey-background;

  button {
    width: 100%;
  }
  > section {
    padding-bottom: $base-2;
  }

  form {
    margin: 0 0 $base-2;
    .twoColumn {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $base-1;
    }
    input {
      margin: 0 0 $base-1;
    }
    label {
      margin: 0 0 $base-1;
      display: block;
    }
  }
  h3,
  p {
    text-align: center;
    justify-content: center;
    margin: $base-1 0;
  }
  .confirmNewsletter {
    display: flex;
    // justify-content: center;
    align-items: center;
    margin: $base-2 0;
    input {
      margin-right: $base-1;
    }
    label,
    input {
      margin-bottom: 0;
    }
    label {
      cursor: pointer;
      user-select: none;
    }
  }
  .login {
    margin-top: $base-2;
    display: flex;
    justify-content: center;
    button {
      margin-left: $base-s;
      width: auto;
    }
  }
  .socialLogin {
    .facebookButton {
      margin-bottom: $base-1;
      // background: #4267b2 url("@images/icons/facebookLogin.png") no-repeat;
      background-color: #4267b2; //fb brand color
      background-position: $base-1 center;
      transition: opacity 200ms ease-in-out;
      background-size: 30px;
      &:hover {
        // background: #4267b2 url("@images/icons/facebookLogin.png") no-repeat;
        background-color: #4267b2; //fb brand color
        background-position: $base-1 center;
        background-size: 30px;
        opacity: 0.9;
      }
    }
    .googleButton {
      // background: #318af5 url("@images/icons/googleLogin.png") no-repeat;
      background-color: #318af5; //google brand color
      background-position: $base-1 center;
      transition: opacity 200ms ease-in-out;
      background-size: 30px;
      &:hover {
        // background: #318af5 url("@images/icons/googleLogin.png") no-repeat;
        background-color: #318af5; //google brand color
        background-size: 30px;
        background-position: $base-1 center;
        opacity: 0.9;
      }
    }
  }
}
