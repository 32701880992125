/*
 * Name: Create Party
 * Desc:
 *
*/

@import "@styles/variables.scss";

.SelectStyle {
  background: $grey-background;
  padding-bottom: $base-3;
  .styles {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    row-gap: 5px;
    column-gap: 5px;
    padding: 0 $base-2;
    // @media screen and (min-width:$tablet) {
    @media screen and (min-width:$tablet) {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 0 $base-4;
      row-gap: 15px;
      column-gap: 15px;
  }
  }
  .style {
    text-transform: uppercase;
    display: block;
    border: 2px solid transparent;
    cursor: pointer;
    user-select: none;
    text-align: center;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    transition: all 200ms ease-in-out;
    border-radius: 5px;
    opacity: 0.95;
    &:hover {
      border-color: $hopp-dark-blue;
      opacity: 1;
      .image {
        border-color: $white;
      }
      img {
        transform: scale(1.05);
      }
    }
    &:after {
      content: "";
      position: absolute;
      background: linear-gradient(
        rgba(0, 0, 0, 0.45) 0%,
        rgba(0, 0, 0, 0.45) 25%,
        rgba(0, 0, 0, 0.2) 50%,
        rgba(0, 0, 0, 0.1) 100%
      );
      // background: rgba(0,0,0,0.3);
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px;
      border-radius: 5px;
    }
    span {
      position: absolute;
      top: $base-2;
      left: $base-2;
      display: block;
      margin-top: $base-1;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 1.2;
      color: $white;
      z-index: 1;
    }
    .image {
      position: relative;
      overflow: hidden;
      margin: auto;
      border: 2px solid transparent;
      border-radius: 5px;
      transition: all 200ms ease-in-out;
      img {
        transition: all 200ms ease-in-out;
        display: block;
        border-radius: 5px;
        box-sizing: border-box;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: $base-3;
    button {
      width: 180px;
    }
  }
}
