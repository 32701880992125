/*
 * Name: Section Layout
 * Desc:
 *
*/

@import '@styles/variables.scss';

.SiteWrapper {
    width: $site-width-m;
    max-width: $site-max-width;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width:$netbook) {
        width: $site-width-d;
    }
    &.full {
        width: auto;
        max-width: 100%;
        padding: 0 $base-2;
        @media screen and (min-width:$netbook) {
            padding: 0 $base-3;
        }
    }
    &.wide {
        width: auto;
        max-width: 100%;
        padding: 0 $base-3;
        max-width:$site-max-width-wide;
    }
    &.narrow {
        max-width:$site-max-width-narrow;
    }
    &.textNarrow {
        max-width: $site-max-width-text-narrow;
    }
    &.desktopOnly {
        @media screen and (max-width:$netbook) {
            width: 100%;
        }
    }
    &.mobileOnly {
        @media screen and (min-width:$netbook) {
            width: 100%;
            padding: 0;
        }
    }
}