/*
 * Name: SocialLinks
 * Desc:
 *
*/

@import '@styles/variables.scss';

.SocialLinks {
    margin-top: $base-1;
    display: flex;
    justify-content: flex-start;
    span  {
        margin-left:auto;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.13px;
        color: $faded-grey;
    }
    a {
        margin-right: $base-1;
        padding-right: $base-1;
        img {
            height: 20px;
            width: inherit;
            opacity: 0.8;
        }
        &:hover {
            img {
                opacity: 1;
            }
        }
    }
}
