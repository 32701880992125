/*
 * Name: Supplier search location
 * Desc:
 *
*/

@import "@styles/variables.scss";

$locationInputWidth: 200px;

.GeocodeSelect {
    width: 100%;

  .locationInner {
    position: relative;
    width: 100%;
    height: 100%;
    input,
    label {
      position: absolute;
    }
  }
  .suggestions {
    position: absolute;
    top: 100%;
    width: 100%;
    min-width: 250px;
    background: #fff;
    z-index: 5;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid $soft-grey;
    transition: opacity 100ms ease;
    opacity: 0;
    &.active {
      opacity: 1;
    }
    li {
      font-size: 12px;
      background: #fff;
      padding: 10px;
      border-top: 1px solid $light-grey;
      cursor: pointer;
      user-select: none;
      &:first-child {
        border: none;
      }
      transition: all 100ms ease;
      &:hover {
        background: $light-grey;
      }
    }
  }

  input {
      margin: 0;
    padding: 10px;
    padding-right: 30px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    letter-spacing: 1px;
    font-size: 14px;
    border: none;
    outline: none;
    border:none;
    &:focus {
      border:none;
    }
    &::placeholder {
      color: $soft-grey-text;
    }
  }
  label {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding-right: 33px;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    cursor: pointer;
    user-select: none;
  }
  button {
    position: absolute;
    right: 6px;
    background: none;
    border: none;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;
    outline: none;
  }

  input,
  &.editable label {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 200ms, opacity 200ms;
  }

  label,
  &.editable input {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 200ms;
  }
}
