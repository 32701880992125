/*
 * Name: LimitReached
 * Desc:
 *
*/

@import "@styles/variables.scss";

$md-radio-checked-color: $hopp-pink;
$md-radio-border-color: rgba(0, 0, 0, 0.5);
$md-radio-size: 18px;
$md-radio-checked-size: 8px;
$md-radio-ripple-size: 9px;

.LimitReached {
  background-color: $grey-background;
  color: $dark-text;
  text-align: left;
  .grid {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $netbook) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .image {
    max-height: 100vw;
    height: 100%;
    img {
      height: 100%;
      object-fit: cover;
    }
    @media screen and (min-width: $tablet) {
      max-height: 50vw;
    }
    @media screen and (min-width: $netbook) {
      display: grid;
      max-height: none;
    }
  }

  .content {
    padding: $base-3;
    h2 {
      margin-top: -3px;
      font-size: 25px;
    }
    p {
      font-weight: 500;
      letter-spacing: 0.5px;
      opacity: 0.85;
      line-height: 1.5;
      font-size: 15px;
      &.error {
        color: red;
      }
      &.success {
        line-height: 1;
        margin: $base-2 0 0;
        font-weight: 500;
        text-align: center;
        color: white;
        background: $soft-grey;
        padding: 15px 25px;
        border-radius: 3px;
      }
    }
    a {
      color: $dark-text;
      text-decoration: underline;
    }
  }

  form {
    margin: 0;
    button {
      width: 100%;
    }
    input {
      font-size: 16px;
      margin: $base-1 0;
    }

    > label {
      display: block;
      margin: 15px 0;
    }
    h3,
    p {
      text-align: center;
      justify-content: center;
      margin: $base-1 0;
    }
    h3 {
      margin: $base-2 0;
    }

    .group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $base-1;
      // margin-bottom: $base-2;
      margin: $base-1 0;
      input {
        margin: 0;
      }
    }

    .confirmNewsletter {
      display: flex;
      // justify-content: center;
      align-items: center;
      margin: $base-2 0;
      label,
      input {
        margin: 0;
      }
      input {
        margin-right: $base-1;
      }
      label {
        cursor: pointer;
        user-select: none;
      }
    }

    .login {
      margin-top: $base-2;
      display: flex;
      justify-content: center;
      button {
        margin-left: $base-s;
        width: auto;
      }
    }
  }
}
