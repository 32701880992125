/*
 * Name: masthead
 * Desc:
 *
*/

@import '@styles/variables.scss';

.Masthead {
    background: $dark-background;
    padding: 15px 0;
    position: fixed;
    width: 100%;
    z-index: 10;
    // box-shadow: 0 4px 10px 0px transparentize($color: $dark-background, $amount: 0.5);
    transition: top 150ms linear, padding 150ms linear;
    top:0;
    @media screen and (min-width:$netbook) {
        padding: 17px 0;
    }
    &.hideMenu {
        top: -$header-height-m;
        box-shadow: none;
    }
    .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            width: 86px;
            @media screen and (min-width:$tablet) {
                width: 112px;
            }
        }
        .menu {
            background: $dark-background;
            position: absolute;
            z-index: 5;
            height: calc( 100vh - #{$header-height-m} );
            width: 100%;
            top: $header-height-m;
            left:0;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s ease-in-out 300ms, opacity 300ms;
            &.isActive {
                visibility: visible;
                opacity: 1;
                transition: visibility 0s ease-in-out 0s, opacity 300ms;
            }
            @media screen and (min-width:$netbook) {
                position: relative;
                display: flex;
                justify-content: flex-end;
                height: auto;
                top:auto;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.spacer {
    background: $dark-background;
    width:100%;
    height: $header-height-m;
}