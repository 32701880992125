/*
 * Name: NewsletterSignUp
 * Desc:
 *
*/

@import "@styles/variables.scss";

.NewsletterSignUp {
  background-color: $hopp-light-blue;
  color: $dark-text;
  text-align: center;
  a {
    color: $dark-text;
    text-decoration: underline;
  }
  h2,
  p {
    color: $dark-text;
  }
  form {
    margin: $base-3 auto;
    max-width: 685px;
    button {
      width: 100%;
      @media screen and (min-width: $tablet) {
        width: auto;
        position: absolute;
        right: 4px;
        top: 4px;
      }
    }
  }
}
