/*
 * Name: Section Layout
 * Desc:
 *
*/

@import '@styles/variables.scss';



.Section {
    padding: $section-m 0;
    @media screen and (min-width:$netbook) {
        padding: $section-d 0;
    }
    &.inner {
        padding: $section-m*0.75 0;
        @media screen and (min-width:$netbook) {
            padding: $section-d*0.75 0;
        }
    }
    &.margin {
        padding: 0;
        margin-top: $section-m;
        margin-bottom: $section-m;
        @media screen and (min-width:$netbook) {
            margin-top: $section-d;
            margin-bottom: $section-d;
        }
    }
    &.inner.margin {
        margin-top: $section-m*0.75;
        margin-bottom: $section-m*0.75;
        @media screen and (min-width:$netbook) {
            margin-top: $section-d*0.75;
            margin-bottom: $section-d*0.75;
        }
    }
}