/*
 * Name: masthead
 * Desc:
 *
*/

@import '@styles/variables.scss';

.Foundation {
    background: $body-background;

    .grid {
        padding: $section-m 0 $section-m;
        display: flex;
        flex-direction: column;
        @media screen and (min-width:$tablet) {
            // flex-direction: row;
            justify-content: center;
            align-items: center;

        }
        @media screen and (min-width:$laptop) {
            flex-direction: row;
            justify-content: space-between;
        }
        .copyright {
            color: $grey-out;
            font-size: 14px;
        }
        .menus {
            margin-top:$base-2;
            margin-bottom: $base-2;
            @media screen and (min-width:$laptop) {
                margin:0;
            }
        }
        .nav, .subnav {
            display: grid;
            grid-template-columns: 1fr;
            row-gap: $base-1;
            a, span {
                // margin-left: $base-2;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 1px;
            }
            @media screen and (min-width:$tablet) {
               display: flex;
               margin: 0;
               justify-content: center;
               a, span {
                margin-left: $base-2;
               }
            }

        }
        .nav {
            a {
                color: $dark-text;
                font-size: 12px;
                span {
                    margin: 0;
                    text-transform: lowercase;
                }
            }
            @media screen and (min-width:$tablet) {
               margin-bottom: $base-2;
             }
        }
        .subnav {
            margin-top:$base-2;
            a, span {
                color: $grey-out;
                font-size: 10px;
            }
        }


    }
}