/*
 * Name: LoginModal
 * Desc:
 *
*/

@import '@styles/variables.scss';

.Login {

    @media screen and (min-width:$netbook) {
        background: $grey-background;
        section {
            padding-bottom: $base-2;
        }
    }
    form {
        display: flex;
        flex-direction: column;
        padding-bottom: $base-4;
        h2 {
            text-align: center;
            height: $header-height-m;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                display: block;
                width:100%;
            }
        }
        h3 {
            justify-content: center;
        }
        p {
            margin: $base-2 0 0;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            button {
                display: inline-block;
            }
        }
        a {
            color: $dark-text;
            font-weight: 600;
        }
        input  {
            margin: 0 0 $base-1;
        }

        .buttons {
            margin: $base-1 0 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: $base-1;
        }
    }

}
