/*
 * Name: About
 * Desc:
 *
*/

@import "@styles/variables.scss";

.LoadingParty {
  padding-bottom: $base-5;
}
