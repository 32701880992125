/*
 * Name: React Modal
 * Desc:
 *
*/

@import '@styles/variables.scss';

.overlay {
    z-index: 1150; //gatsby page link uses a z-index of 1100 for anything in TransitionPortal
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @media screen and (min-width:$netbook) {
        background: rgba($dark-background,0.85);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;
        @media screen and (max-height: 780px) {
            align-items: flex-start;
        }
    }
}



.modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $grey-background;
    outline: none;
    overflow: auto;
    @media screen and (min-width:$netbook) {
        background: $body-background;
        position: relative;
        top: auto;
        bottom: auto;
        max-width: 500px;
        flex: 1;
        margin-bottom:$base-4;
        margin-top:$base-4;
        overflow: unset;
    }
    > section {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        @media screen and (min-width:$netbook) {
            max-width: 500px;
            margin: auto;
            min-height: 0;
        }
    }
    h1 {
        text-transform: none;
    }
    header {
        background: $body-background;
        height: $header-height-m;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $light-line;
        h2 {
            margin:0;
        }
        @media screen and (min-width:$netbook) {
            display: block;
            height: auto;
            border: none;
            padding: $base-3 $base-3 0;
        }
    }
    main {
        margin-top: $base-1 + $base-s;
        padding-top: $base-3;
        padding-bottom: $base-3;
        border-top: 1px solid $light-line;
        background: $body-background;
        flex:1;
        @media screen and (min-width:$netbook) {
            margin-top: 0;
            border: none;
            flex: 0;
            padding-top: $base-2;
        }
    }
    > footer {
        position: fixed;
        bottom:0;
        left:0;
        right:0;
        padding:$base-3;
        background: $body-background;
        box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.05);
        @media screen and (min-width:$netbook) {
            box-shadow: none;
            padding-top:0;
            position: relative;

        }
    }
    .close {
        z-index: 15;
        position: absolute;
        top: $header-height-m*0.5;
        transform: translateY(-50%);
        right: $base-3;
        background: none;
        border:none;
        -webkit-appearance: none;
        outline:none;
        img {
            width: 16px;
        }
        @media screen and (min-width:$netbook) {
            top: $base-3;
            transform: none;
        }
    }
}