/*
 * Name: Menu
 * Desc:
 *
*/
@use 'sass:math';

@import "@styles/variables.scss";

.Menu {
  background: $dark-background;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media screen and (max-width: $netbook) {
    overflow: auto;
  }
  a {
    opacity: 0.85;
    transition: opacity 150ms ease;
    &:hover {
      opacity: 1;
    }
  }
  ul,
  .buttons {
    display: flex;
    flex-direction: column;
  }
  ul {
    li {
      border-top: 1px solid $dark-line;
      padding: math.div(100% - $site-width-m, 2);
      a {
        color: $light-text;
        img {
          margin-right: $base-1;
        }
        span {
          text-transform: lowercase;
        }
      }
    }
  }

  .submenu {
    position: absolute;
    top: 50px;
    // width: 200px;
    padding-top: $base-1;

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
    ul {
      display: grid;
      background: $dark-background;
      padding: $base-2;
      row-gap: $base-2;
      border: 1px solid $dark-line;
      border-radius: 3px;
      box-shadow: $elevation-2;
      button {
        margin: 0;
      }
    }
    @media screen and (min-width: $netbook) {
      &.active {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
      }
    }
  }

  .accountMenu {
    button {
      background: none;
      border: none;
      cursor: pointer;
      outline: none;
      font-size: 17px;
      display: flex;
      align-items: center;
      padding: 0;
      font-weight: 500;
      color: $light-text;
    }
    a {
      display: flex;
      align-items: center;
      line-height: 1;
      img {
        height: 16px;
      }
    }
    li {
      position: relative;
      .profile {
        position: absolute;
        right: $base-3 + $base-s;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(174, 178, 201, 0.28);
        border-radius: 100%;
        line-height: 33px;
        width: 35px;
        height: 35px;
        &.admin {
          border: 1px solid $hopp-orange;
        }
      }
    }
  }

  .buttons {
    border-top: 1px solid $dark-line;
    flex: 1;
    padding: $base-3*0.5 (100% - $site-width-m)*0.5 $base-3;
    a {
      margin-top: $base-1 + $base-s;
      width: auto;
    }
    button {
      font-weight: 500;
      margin-top: $base-1 + $base-s;
      width: 100%;
    }
  }
  @media screen and (min-width: $netbook) {
    &,
    ul,
    .buttons {
      flex-direction: row;
      align-items: center;
    }

    ul li,
    .buttons {
      border: none;
      padding: 0;
      a,
      button {
        margin: 0;
        margin-left: 14px;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        width: auto;
      }
    }

    .buttons a,
    button {
      padding: 10px 11px;
    }

    .accountMenu {
      order: 3;
      margin-left: $base-2;

      li a {
        margin-left: 0;
      }
      button {
        margin: 0;
        font-size: 13px;
        text-transform: uppercase;
      }

      li .profile {
        position: relative;
        right: 0;
        margin-left: $base-1;
      }

      ul {
        position: absolute;
        top: 60px;
        border-radius: 3px;
        box-shadow: $elevation-2;
        width: 200px;
        right: 0;
        display: grid;
        background: $dark-background;
        padding: $base-2;
        row-gap: $base-2;
        border: 1px solid $dark-line;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
        &.active {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 0s, opacity 300ms;
        }
      }
    }
  }
  @media screen and (min-width: 1150px) {
    ul li,
    .buttons {
      a,
      button {
        margin-left: $base-2;
      }
    }
  }
  @media screen and (min-width: $laptop) {
    ul li {
      a {
        margin-right: $base-1;
        // margin-left: 0;
      }
    }
  }
}
